import * as Yup from 'yup';

// Utility function to strip non-digit characters
export const stripNonDigits = (input: string) => input.replace(/\D/g, '');

// Utility function to format phone number
const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = stripNonDigits(phoneNumber);
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

// Custom Yup test for US/Canada phone numbers
const getIsValidUSCAPhoneNumber = (def: boolean) => ({
  name: 'isValidUSCAPhoneNumber',
  test: function(value: string | undefined) {
    if (!value) { return def; }
    const digits = stripNonDigits(value);
    return (digits.length === 11 && digits.startsWith('1'));
  },
  message: 'Invalid phone number'
});

// Reusable Yup schema for phone number validation
const phoneNumberSchema = Yup.string()
  .test(getIsValidUSCAPhoneNumber(false))
  .transform((value) => {
    const formatted = formatPhoneNumber(value);
    return formatted || value;
  })
  .required('Phone number is required');

const optionalPhoneNumberSchema = Yup.string()
  .test(getIsValidUSCAPhoneNumber(true))
  .transform((value) => {
    if (value) {
      const formatted = formatPhoneNumber(value);
      return formatted || value;
    }

    return value;
  })
  .optional();

export { phoneNumberSchema, optionalPhoneNumberSchema, formatPhoneNumber };
