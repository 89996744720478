import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  CreateLocationInput,
  LocationType,
} from 'Generated/graphql';
import ButtonWithSpinner from 'Common/components/ButtonWithSpinner';
import InputWithLabel from 'Common/components/InputWithLabel';
import GooglePlacesAutocomplete from 'Common/components/GooglePlacesAutocomplete';
import { phoneNumberSchema } from 'Common/functions/PhoneNumber';

type CreateLocationFormProps = {
  isDisabled?: boolean;
  onLocationCreated: ((uid: string | undefined) => void) | undefined;
  onCancel: () => void;
  createLocation: (v: CreateLocationInput) => Promise<string | undefined>;
  loading: boolean;
  hideName?: boolean;
}

const CreateLocationForm: React.FC<CreateLocationFormProps> = ({
  isDisabled,
  onLocationCreated,
  onCancel,
  createLocation,
  loading,
  hideName,
}) => {

  const formik = useFormik({
    initialValues: {
      name: 'Home',
      phoneNumber: '',
      placeId: '',
      extendedAddress: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Enter a name for this location'),
      phoneNumber: phoneNumberSchema,
      placeId: Yup.string().required('Please enter the address and select from the list'),
    }),
    onSubmit: async (values) => {
      try {
        const createdLocationUid = await createLocation({
          name: values.name,
          placeId: values.placeId,
          phoneNumber: values.phoneNumber,
          type: LocationType.Individual,
          extendedAddress: values.extendedAddress,
        });

        onLocationCreated && onLocationCreated(createdLocationUid);
      } catch (error) {
        console.error('Error creating location', error);
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col w-full gap-4">
      {hideName || (
        <InputWithLabel
          touched={Boolean(formik.touched.name)}
          error={formik.errors.name}
          label="Name"
        >
          <input
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            className="input-lg"
          />
        </InputWithLabel>
      )}

      <InputWithLabel
        touched={Boolean(formik.touched.placeId)}
        error={formik.errors.placeId}
        label="Address"
      >
        <GooglePlacesAutocomplete
          data-testid="locationForm_placeIdInput"
          onSelect={p => {
            if (p) {
              formik.setFieldValue('placeId', p.place_id);
            }
          }}
          inputProps={{
            onBlur: formik.handleBlur,
          }}
        />
      </InputWithLabel>

      <InputWithLabel
        touched={Boolean(formik.touched.extendedAddress)}
        label="Apt/Unit/Suite #"
      >
        <input
          name="extendedAddress"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.extendedAddress}
          className="input-lg"
        />
      </InputWithLabel>

      <div className="flex gap-4">
        <button
          type="button"
          className="btn"
          onClick={() => onCancel()}
        >
          Cancel
        </button>
        <ButtonWithSpinner
          isLoading={loading}
          testid="locationFormSubmit"
          isDisabled={isDisabled}
        />
      </div>
    </form>
  );
};

export default CreateLocationForm;

