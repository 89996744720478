export enum AppCookie {
  ClosedJoinSharedLocationPrompt = 'cjsp',
  PeazyIsLoggedIn = 'mimoblaili',
  PeazySession = 'mimobl_session',
  MimoblSkipAuth = '_mimoblsa',
  BusinessSelectedLocationUid = 'bslu',
  CustomerSelectedLocationId = 'csli',
  CustomerAppointmentCartUidMap = 'cacum',
  CustomerBookingCartUidMap = 'cbcum',
  CustomerBookingRecommendedStartDateTime = 'cbrsdt',
  ServiceProviderDismissLinkGoogleCalendarPrompt = 'spdlgcp',

  // Deprecated
  SEEN_SERVICE_OFFERING_MODAL = 'ssom',
}

const isSSR = typeof window === 'undefined';

export const setCookie = (
  name: AppCookie,
  value: string,
  ttlDays?: number,
): void => {
  const d = new Date;
  d.setTime(d.getTime() + (ttlDays || 10000) * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = name + '=' + value + ';' + expires + ';path=/' + ';domain=' + process.env.NEXT_PUBLIC_COOKIE_DOMAIN;
};

export const getCookie = (name: AppCookie): string | null => {
  if (isSSR) {
    return null;
  }

  const key = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(key) === 0) {
      return c.substring(key.length, c.length);
    }
  }
  return null;
};

