'use client';
import { ServiceType } from '@bookpeazy/common-js';
import Footer from 'Common/components/Footer';
import ServiceTypeSelect from 'Common/components/ServiceTypeSelect';
import { AccountType } from 'Common/functions/AccountType';
import { SearchParam } from 'Common/functions/Search';
import { NextPage } from 'next';
import { AppRoutePath } from 'Pages/routes';
import { useEffect, useState } from 'react';
import { ThemeUIProvider } from 'theme-ui';
import signInAnonymously from 'Common/functions/signInAnonymously';
import { getAuth } from 'firebase/auth';
import PromptCreateHomeLocation from 'Common/components/PromptCreateHomeLocation';
import { AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { ConfigProvider } from 'antd';
import { AntdConfigProviderTheme } from 'Common/functions/antdTheme';
import theme from 'Common/theme';
import 'Clients/FireBaseClient';
import { GetUser } from 'Common/functions/getUser';
import { GetLocationsForNavQueryHookResult } from 'Generated/graphql';
import { enabledCustomerServiceTypes } from '../ServiceTypeSelect/fn';

const navigateToServiceTypePage = (
  serviceType: ServiceType,
  query: URLSearchParams | null,
  routerPush: AppRouterInstance['push'],
) => {
  const pathname = AppRoutePath.BROWSE_SERVICE_PROVIDERS
    .replace('[serviceType]', serviceType);
  const searchRaw = query ? `?${query.toString()}` : '';
  const url = `${pathname}${searchRaw}`;
  routerPush(url);
};

type BrowseServiceTypesProps = {
  user: GetUser | null;
  routerPush: AppRouterInstance['push'];
  refetchLocations: GetLocationsForNavQueryHookResult['refetch'];
  selectedLocation: { id: string } | null;
  navigation: React.ReactNode;
}

const BrowsePageContent: NextPage<BrowseServiceTypesProps> = ({
  user,
  routerPush,
  refetchLocations,
  selectedLocation,
  navigation,
}) => {
  const accountType = user?.accountType;

  const [returnToServiceType, setReturnToServiceType] =
    useState<ServiceType | undefined>(undefined);
  const [isPromptCreateLocationModelOpen, setIsPromptCreateLocationModelOpen] =
    useState(false);

  const onSelectServiceTypeCallback = (serviceType: ServiceType) => {
    // If we are logged in and have a location selected for the appointment
    if (selectedLocation && selectedLocation.id) {
      navigateToServiceTypePage(serviceType, null, routerPush);
    } else if (serviceType) {
      setReturnToServiceType(serviceType);
      setIsPromptCreateLocationModelOpen(true);
    } else {
      setIsPromptCreateLocationModelOpen(true);
    }
  };

  useEffect(() => {
    if (accountType === AccountType.LocationOwner) {
      routerPush(AppRoutePath.BUSINESS_BROWSE_SERVICE_TYPES);
    } else if (accountType === AccountType.ServiceProvider) {
      routerPush(AppRoutePath.SERVICE_PROVIDER_DASHBOARD);
    }
  }, [accountType]);

  return (
    <ThemeUIProvider theme={theme}>
      <ConfigProvider theme={AntdConfigProviderTheme}>
        <div className="flex flex-col min-h-screen bg-offWhite">
          {navigation}
          <div className="flex flex-col w-full grow pb-6">
            <ServiceTypeSelect
              onSelect={(serviceType) => {
                onSelectServiceTypeCallback(serviceType);
              }}
              filter={t => {
                const fa = t.filter(
                  s => enabledCustomerServiceTypes.includes(s)
                );
                return fa;
              }}
            />
          </div>
          <Footer />
        </div>

        <PromptCreateHomeLocation
          isOpen={isPromptCreateLocationModelOpen}
          onClose={() => setIsPromptCreateLocationModelOpen(false)}
          onBeforeSubmit={next => {
            signInAnonymously(getAuth()).finally(next);
          }}
          onFlowComplete={(loc) => {
            setIsPromptCreateLocationModelOpen(false);

            if (returnToServiceType) {
              navigateToServiceTypePage(returnToServiceType, new URLSearchParams({
                [SearchParam.BROWSE_SERVICE_PROVIDERS_LAT]: loc.latitude.toString(),
                [SearchParam.BROWSE_SERVICE_PROVIDERS_LNG]: loc.longitude.toString(),
              }), routerPush);
            } else {
              refetchLocations().catch(console.error);
            }
          }}
        />
      </ConfigProvider>
    </ThemeUIProvider>
  );
};

export default BrowsePageContent;

